import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Header, Icon, Table } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import Moment from 'react-moment';
import { Redirect,Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { InsecureHTML, PageProps, UserProfile, UserSearchResult } from '../types/types';

export default function ReportUserList(props: PageProps) {

    const supportedCountries = [
        { key: 'dk', value: 'dk', flag: 'denmark', text: 'Danmark' },
        { key: 'de', value: 'de', flag: 'germany', text: 'Deutschland' },
        { key: 'uk', value: 'en', flag: 'uk', text: 'United Kingdom / Other' },
        { key: 'no', value: 'no', flag: 'no', text: 'Norge' },
        { key: 'se', value: 'se', flag: 'se', text: 'Sverige' },
        { key: 'fi', value: 'fi', flag: 'fi', text: 'Suomi' }
    ]

    const getReportCountryByCode = (code: string): string => {
        var countryName = ""
        for (let i=0; i<supportedCountries.length; i++) {
            if (supportedCountries[i].key === code) {
                countryName = supportedCountries[i].text
            }
        }
        return countryName
    }

    const getFlag = (code: string): string => {
        for (let i=0; i<supportedCountries.length; i++) {
            if (supportedCountries[i].key === code) {
                return supportedCountries[i].flag
            }
        }
        return ""
    }

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    const [accounts, setAccounts] = useState<UserProfile[]>([])

    const getFormattedAddress = (account: UserProfile): InsecureHTML => {
        const lines = []
        if (account.Address1 !== "") {
            lines.push(account.Address1)
        }
        if (account.Address2 !== "") {
            lines.push(account.Address2)
        }
        let lastLine = ""
        if (account.Postcode !== "") {
            lastLine = account.Postcode
        }
        if (account.City !== "") {
            if (lastLine !== "") {
                lastLine = lastLine + ", "
            }
            lastLine = lastLine + account.City
        }
        if (lastLine !== "") {
            lines.push(lastLine)
        }
        if (account.Country !== "") {
            lines.push(getReportCountryByCode(account.Country))
        }
        return {__html: lines.join("<br/>")};
    }

    const convertToDateString = (input: string): string => {
        return input.replace(" +0000 UTC", "")
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            axios.get<UserSearchResult>("/api/manage/reports/users")
                .then(response => {
                    setAccounts(response.data.Accounts)
                })
                .catch(err => console.log(err))
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container className="page">
            <Header as='h3'>{t("app-menu.reports-users")} ({accounts.length})</Header>

            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('common-headers.user')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.company')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.address')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.contact')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.status')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {accounts.map((account, i) => {
                        return <Table.Row>
                            <Table.Cell>
                                {account.FirstName} {account.LastName}<br/>
                                <Link to={"/app/manage/reports/usage/user/"+account.ID}>{t('reports.user-list.labels.usage')} &raquo;</Link>
                            </Table.Cell>
                            <Table.Cell>{account.Company}<br/>{account.Position}</Table.Cell>
                            <Table.Cell><span dangerouslySetInnerHTML={getFormattedAddress(account)} /></Table.Cell>
                            <Table.Cell>
                                <Icon name='paper plane' />{account.EmailAddress}<br/>
                                <Icon name='phone' /> {account.Telephone}<br/>
                                <Icon name='assistive listening systems' /> <i className={getFlag(account.Language)+" flag"}></i><br/>
                            </Table.Cell>
                            <Table.Cell>{account.EmailVerified
                                ? <span>{t('reports.user-list.status.verified')}</span>
                                : <span>{t('reports.user-list.status.unverified')}</span> }<br/>
                                {t('reports.user-list.labels.activated')}<br/>
                                <strong><Moment format="YYYY-MM-DD HH:mm:ss">{convertToDateString(account.ActivatedAt)}</Moment></strong><br/>
                                {t('reports.user-list.labels.last-login')}<br/>
                                <strong>{account.LastLoginAudit
                                    ? <Moment format="YYYY-MM-DD HH:mm:ss">{account.LastLoginAudit.CreatedAt}</Moment>
                                    : <>-</>
                                }</strong>
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
            
        </Container>
    )

}
