import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Checkbox, CheckboxProps, Container, Dropdown, DropdownProps, Form, Header, Message } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import {
    Redirect
  } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import { idFromLocation, handleNoAccess } from '../utils/utils';
import { PageProps, UserActivation } from '../types/types';

export default function UserActivate(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    
    const [selectedUserID, setSelectedUserID] = useState("")
    const [emailAddress, setEmailAddress] = useState("")

    const [selectedEditUserRole, setSelectedEditUserRole] = useState("")
    const [selectedEditUserCanManageDatasets, setSelectedEditUserCanManageDatasets] = useState(false)
    const [selectedEditUserCanManageNews, setSelectedEditUserCanManageNews] = useState(false)
    const [selectedEditUserCanManageReports, setSelectedEditUserCanManageReports] = useState(false)
    const [selectedEditUserCanManageUsers, setSelectedEditUserCanManageUsers] = useState(false)

    const [errors, setErrors] = useState<string[]>([])

    const roleOptions = [
        {
            key: "is-employee",
            text: t('role-options.employee'),
            value: "is-employee"
        },
        {
            key: "is-partner",
            text: t('role-options.partner'),
            value: "is-partner"
        },
        {
            key: "is-wholesale",
            text: t('role-options.wholesale'),
            value: "is-wholesale"
        }
    ]

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const roles = []

        if (selectedEditUserRole.length > 0) {
            roles.push(selectedEditUserRole)
            roles.push("can-search")
        }
        if (selectedEditUserCanManageDatasets) {
            roles.push("can-manage-datasets")
        }
        if (selectedEditUserCanManageNews) {
            roles.push("can-manage-news")
        }
        if (selectedEditUserCanManageReports) {
            roles.push("can-manage-reports")
        }
        if (selectedEditUserCanManageUsers) {
            roles.push("can-manage-users")
        }

        axios.post<void>(`/api/manage/users/waiting-room/activate/${selectedUserID}`, {roles: roles}, {
            headers: { 'Content-Type': 'multipart/form-data' } })
            .then(_ => props.history.push("/app/manage/users/waiting"))
            .catch(reason => {
                setErrors([t('user-edit.errors.request-failed', `HTTP status: ${reason.response.status}`)])
            })
    }

    const cancelAccountActivate = () => {
        props.history.push("/app/manage/users/waiting")
    }

    const updateSelectedEditUserAdminDatasets = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageDatasets(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminNews = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageNews(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminReports = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageReports(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminUsers = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageUsers(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }
    
    const updateEditUserRole = (sourceEvent: React.SyntheticEvent<HTMLElement, Event>, item: DropdownProps) => {
        setSelectedEditUserRole(item.value as string)
    }

    const fetchUserForActivate = () => {
        const userID = idFromLocation()
        setSelectedUserID(userID)
        axios.get<UserActivation>(`/api/manage/users/waiting-room/activate/${userID}`)
            .then(response => {
                setEmailAddress(response.data.EmailAddress)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchUserForActivate()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container text className="page withBottom">

            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.users-existing")} / {t('common-buttons.activate')}</Header>

            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>{t('common-labels.email-address')}</label>
                    <strong>{emailAddress}</strong>
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.select-role')}</label>
                    <Dropdown onChange={updateEditUserRole}
                        value={selectedEditUserRole}
                        placeholder={t('common-labels.select-role')} fluid selection
                        options={roleOptions} />
                </Form.Field>

                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-datasets')}
                        onChange={updateSelectedEditUserAdminDatasets}
                        checked={selectedEditUserCanManageDatasets} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-news')}
                        onChange={updateSelectedEditUserAdminNews}
                        checked={selectedEditUserCanManageNews} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-reports')}
                        onChange={updateSelectedEditUserAdminReports}
                        checked={selectedEditUserCanManageReports} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-users')}
                        onChange={updateSelectedEditUserAdminUsers}
                        checked={selectedEditUserCanManageUsers} />
                </Form.Field>

                <Button basic color='red' type="submit">{t('common-buttons.activate')}</Button>
                <Button basic color='green' onClick={() => cancelAccountActivate()}>{t('common-buttons.cancel')}</Button>
            </Form>
            {errors.length === 0
                ? null
                : <Message
                    error
                    header={t('user-edit.errors.header')}
                    list={errors}
                /> }
        </Container>
    )

}
