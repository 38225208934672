import React from 'react';
import { Container, Header } from 'semantic-ui-react'
import 'react-data-grid/dist/react-data-grid.css';

import { useTranslation } from 'react-i18next';

import DatasetUpload from './Component.DatasetUpload.js'
import { Redirect } from 'react-router-dom';
import { PageProps } from '../types/types.js';

export default function ManageDatasetsNew(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    
    const onUploadComplete = (progress: any) => {
        props.history.push("/app/manage/datasets/existing")
    }

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page">
            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.datasets-new")}</Header>
            <DatasetUpload onUploadComplete={onUploadComplete} />
        </Container>
    )
}