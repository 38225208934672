import { CharacterClass } from "../types/types";

export function countDigits(input: string): number {
    return countCharactersInCalss(input, CharacterClass.DIGIT)
}

export function countLowerCase(input: string): number {
    return countCharactersInCalss(input, CharacterClass.LOWERCASE)
}

export function countSpecial(input: string): number {
    return countCharactersInCalss(input, CharacterClass.SPECIAL)
}

export function countUpperCase(input: string): number {
    return countCharactersInCalss(input, CharacterClass.UPPERCASE)
}

export function countCharactersInCalss(input: string, charClass: CharacterClass): number {
    let count = 0
    var i = 0
    var c = ""
    switch (charClass) {
        case CharacterClass.DIGIT:
            for (i=0; i<input.length; i++) {
                c = input[i]
                if (c >= '0' && c <= '9') {
                    count = count + 1
                }
            }
            return count
        case CharacterClass.UPPERCASE:
            for (i=0; i<input.length; i++) {
                // check if letter:
                c = input[i]
                if (c.toLowerCase() !== c.toUpperCase()) { // it's a letter, after: https://stackoverflow.com/a/32567789
                    if (c === c.toUpperCase()) {
                        count = count + 1
                    }
                }
            }
            return count
        case CharacterClass.LOWERCASE:
            for (i=0; i<input.length; i++) {
                // check if letter:
                c = input[i]
                if (c.toLowerCase() !== c.toUpperCase()) { // it's a letter, after: https://stackoverflow.com/a/32567789
                    if (c === c.toLowerCase()) {
                        count = count + 1
                    }
                }
            }
            return count
        case CharacterClass.SPECIAL:
            for (i=0; i<input.length; i++) {
                // check if letter:
                c = input[i]
                if (c.toLowerCase() === c.toUpperCase()) { // it's not a letter but can be a digit, after: https://stackoverflow.com/a/32567789
                    if (c >= '0' && c <= '9') {
                        continue
                    }
                    count = count + 1
                }
            }
            return count
    }
    return 0
}