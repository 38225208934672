import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import Cookies from "universal-cookie";

import axios from 'axios';

import { Container, Dropdown, Grid, Icon, Header, DropdownProps } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import ManageDatasetsExisting from './datasets/Component.ManageDatasetsExisting'
import ManageDatasetsNew from './datasets/Component.ManageDatasetsNew'

import ManageNewsEdit from './news/Component.ManageNewsEdit'
import ManageNewsExisting from './news/Component.ManageNewsExisting'
import ManageNewsNew from './news/Component.ManageNewsNew'
import ReadNews from './news/Component.ReadNews'

import ReportUsageGeneric from './reports/Component.ReportUsageGeneric';
import ReportUsageUser from './reports/Component.ReportUsageUser';
import ReportUserList from './reports/Component.ReportUserList';

import ManageUsersWaiting from './users/Component.ManageUsersWaiting'
import ManageUsersExisting from './users/Component.ManageUsersExisting'
import UserActivate from './users/Component.UserActivate'
import UserEdit from './users/Component.UserEdit'

import ProfileChangedPassword from './profile/Component.ChangedPassword'
import ProfileChangePassword from './profile/Component.ChangePassword'
import ProfileView from './profile/Component.ProfileView'

import Search from './Component.Search'
import SignIn from './Component.SignIn'
import SignOut from './Component.SignOut'
import SignUp from './Component.SignUp'
import SignedUp from './Component.SignedUp'

import { useTranslation } from 'react-i18next';

import { Authorization, Permission } from './types/features';
import { HistoryOnlyProps } from './types/types';

import logo from './images/logo_gruen_footer.svg'
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface MenuItem {
  divider?: boolean,
  icon?: string,
  label?: string,
  uri?: string,
}

export default function App(props: HistoryOnlyProps) {

  const cookies = new Cookies()
  var cookieValue = cookies.get("app-session-id")

  const [signInHandled, setSignInHandled] = useState(false)
  const [sessionID, setSessionID] = useState(cookieValue === undefined ? null : cookieValue)

  const [loggedInUserIsAdmin, setLoggedInUserIsAdmin] = useState(false)
  const [adminMenuItems, setAdminMenuItems] = useState<MenuItem[]>([])
  const [loggedInMenuItems, setLoggedInMenuItems] = useState<MenuItem[]>([])

  const { t, i18n } = useTranslation();

  const supportedLanguages = [
    { key: 'dk', value: 'dk', flag: 'denmark', text: 'Dansk' },
    { key: 'de', value: 'de', flag: 'germany', text: 'Deutsch' },
    { key: 'uk', value: 'en', flag: 'uk', text: 'English' },
    { key: 'no', value: 'no', flag: 'no', text: 'Norsk' },
    { key: 'se', value: 'se', flag: 'se', text: 'Svenska' },
    { key: 'fi', value: 'fi', flag: 'fi', text: 'Suomalainen' }
  ]

  const onLanguageChange = (eventSource: React.SyntheticEvent<HTMLElement, Event>, eventData: DropdownProps) => {
    i18n.changeLanguage(eventData.value as string)
    axios.get<Authorization>("/api/features")
      .then(response => translatedMenuItems(response.data.permissions))
      .catch(e => console.log(e))
  }

  const handleSignIn = (givenSessionID: string) => {
    if (givenSessionID !== null) {
      cookies.set("app-session-id", givenSessionID, {
        path: "/",
        httpOnly: false,
        secure: window.location.protocol === "https:",
        sameSite: window.location.protocol === "https:" ? 'none' : 'strict'
      })
      setSessionID(givenSessionID)
      setSignInHandled(true)
    }
    axios.get<Authorization>("/api/features")
      .then(response => translatedMenuItems(response.data.permissions))
      .catch(e => console.log(e))
  }

  const translatedMenuItems = (permissions: Permission[]) => {

    var adminMenuItems: MenuItem[] = []
    var menuItems: MenuItem[] = []

    permissions.forEach(permission => {
      if (permission.scopes === null) return
      permission.scopes.forEach(scope => {
        if (scope.indexOf("globalconnect:manage:") > -1) {

          const nonPrefixedScope = scope.replace("globalconnect:","")

          if (nonPrefixedScope === "manage:datasets") {
            adminMenuItems.push({ uri: `/app/manage/datasets/existing`, label: t(`app-menu.datasets-existing`), icon: 'boxes' })
            adminMenuItems.push({ uri: `/app/manage/datasets/new`, label: t(`app-menu.datasets-new`), icon: 'dolly' })
            adminMenuItems.push({ divider: true })
          }
          if (nonPrefixedScope === "manage:reports") {
            adminMenuItems.push({ uri: `/app/manage/reports/users`, label: t(`app-menu.reports-users`), icon: 'address book' })
            adminMenuItems.push({ uri: `/app/manage/reports/usage/overview`, label: t(`app-menu.reports-usage`), icon: 'sliders horizontal' })
            adminMenuItems.push({ divider: true })
          }
          if (nonPrefixedScope === "manage:news") {
            adminMenuItems.push({ uri: `/app/manage/news/existing`, label: t(`app-menu.news-existing`), icon: 'copy' })
            adminMenuItems.push({ uri: `/app/manage/news/new`, label: t(`app-menu.news-new`), icon: 'file alternate' })
            adminMenuItems.push({ divider: true })
          }
          if (nonPrefixedScope === "manage:users") {
            adminMenuItems.push({ uri: `/app/manage/users/existing`, label: t(`app-menu.users-existing`), icon: 'users' })
            adminMenuItems.push({ uri: `/app/manage/users/waiting`, label: t(`app-menu.users-waiting`), icon: 'child' })
            adminMenuItems.push({ divider: true })
          }
          
        }
        if (scope === "globalconnect:search") {
          menuItems.push({ uri: `/app/search`, label: t(`app-menu.search`) })
        }
      })
    })
    
    setLoggedInUserIsAdmin(menuItems.length > 0)
    setAdminMenuItems(adminMenuItems)
    setLoggedInMenuItems(menuItems)
  }

  const handleSignOut = () => {
    cookies.remove("app-session-id", {
      path: "/",
      domain: window.location.hostname
    })
    setSessionID(null)
  }

  useEffect(() => {
    if (sessionID !== null && sessionID !== undefined) {
      if (!signInHandled) {
        axios.get("/api/session-validate")
          .then(response => {
            // any result other 200 implies invalid session
            // so here, we simply accept that the session is valid
            handleSignIn(sessionID)
          })
          .catch(reason => {
            // any result other 200 implies invalid session
            // so here, we simply reject the session
            handleSignOut()
            props.history.push("/app/signin")
          })
      }
    }
  })

  return (
    <>
      <Container fluid className="pageContent">
        <Container fluid>
          <Dropdown className="languageSelector"
            selection
            options={supportedLanguages}
            value={i18n.language}
            onChange={onLanguageChange} />
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column className="appheader" textAlign='center'>
                <img src={logo} className="headlogo" />
              </Grid.Column>
              {sessionID === null
                ? <Grid.Column className="appheader nav">
                    <Link to="/app/signin">{t('app-menu.sign-in')}</Link>
                    <Link to="/app/signup">{t('app-menu.sign-up')}</Link>
                  </Grid.Column>
                : <Grid.Column className="appheader nav">
                    { loggedInMenuItems.map(link => {
                      if (link.uri !== undefined && link.label !== undefined) {
                        return <Link to={link.uri}>{link.label}</Link>
                      }
                      return <></>
                    })}
                    {loggedInUserIsAdmin
                      ? <Dropdown inline
                          text={t('app-menu.administration')}>
                          <Dropdown.Menu>
                            { adminMenuItems.map(link => {
                              return (link.divider !== undefined
                                ? <Dropdown.Divider />
                                : <Dropdown.Item>
                                    { link.icon !== undefined
                                      ? <Icon name={link.icon as SemanticICONS} />
                                      : <></> }
                                    { link.uri !== undefined && link.label !== undefined
                                      ? <Link to={link.uri}>{link.label}</Link>
                                      : <></> }
                                  </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      : <></> }
                    <Link to="/app/profile/view">{t('app-menu.profile')}</Link>
                    <Link className="orange" to="/app/signout">{t('app-menu.sign-out')}</Link>
                  </Grid.Column>
              }
            </Grid.Row>
          </Grid>
        </Container>
        <Switch>
        <Route path="/app/profile/changed/password">
            <ProfileChangedPassword history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/profile/password-change">
            <ProfileChangePassword history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/profile/view">
            <ProfileView history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/read-news">
            <ReadNews history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/signin">
            <SignIn callback={handleSignIn} history={props.history} />
          </Route>
          <Route path="/app/signup">
            <SignUp history={props.history} />
          </Route>
          <Route path="/app/signed/up">
            <SignedUp />
          </Route>
          <Route path="/app/signout">
            <SignOut callback={handleSignOut} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/datasets/existing">
            <ManageDatasetsExisting history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/datasets/new">
            <ManageDatasetsNew history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/news/edit">
            <ManageNewsEdit history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/news/existing">
            <ManageNewsExisting history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/news/new">
            <ManageNewsNew history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/reports/usage/overview">
            <ReportUsageGeneric history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/reports/usage/user">
            <ReportUsageUser history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/reports/users">
            <ReportUserList history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/users/existing">
            <ManageUsersExisting history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/users/activate">
            <UserActivate history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/users/edit">
            <UserEdit history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/manage/users/waiting">
            <ManageUsersWaiting history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/search">
            <Search history={props.history} sessionID={sessionID} />
          </Route>
          <Route path="/app/finished">
            <Container text className="page centered">
              <p>{t('app.signedout')}</p>
            </Container>
          </Route>
        </Switch>
      </Container>
      <Container fluid className="footer">
        <Container>
            <Grid>
              <Grid.Row columns="4">
                <Grid.Column>
                  <Header className="footerText">©GlobalConnect 2020</Header>
                </Grid.Column>
                <Grid.Column className="footerText">
                  <a href="tel:+49 40 2999-7670" className="footerText">
                    <span className="text-uppercase">Telefon:</span> +49 40 2999-7670
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <a href="mailto:info@globalconnect.de" className="footerText">
                    <span className="text-uppercase">E-mail:</span> info@globalconnect.de
                  </a>
                </Grid.Column>
                <Grid.Column className="footerText">
                  <a className="footerText" href="https://www.globalconnect.de/kontakt/">Kontakt</a>&nbsp;|&nbsp;
                  <a className="footerText" href="https://www.globalconnect.de/impressum/">Impressum</a>&nbsp;|&nbsp;
                  <a className="footerText" href="https://www.globalconnect.de/datenschutz/">Datenschutz</a>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        </Container>
      </Container>
    </>
  )
}
