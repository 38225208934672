import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Checkbox, CheckboxProps, Container, Dropdown, DropdownProps, Form, Header, Input, TextArea, TextAreaProps } from 'semantic-ui-react'
import 'react-data-grid/dist/react-data-grid.css';

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { NewsGetResponse, PageProps } from '../types/types';
import { handleNoAccess, idFromLocation } from '../utils/utils';

export default function ManageNewsEdit(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const audienceOptions = [
        {
            key: "is-employee",
            text: t('role-options.employee'),
            value: "is-employee"
        },
        {
            key: "is-partner",
            text: t('role-options.partner'),
            value: "is-partner"
        },
        {
            key: "is-wholesale",
            text: t('role-options.wholesale'),
            value: "is-wholesale"
        }
    ]

    const [ editedNewsID, setEditedNewsID ] = useState("")
    const [ newsSubject, setNewsSubject ] = useState("")
    const [ newsContent, setNewsContent ] = useState("")
    const [ newsPublished, setNewsPublished ] = useState(false)
    const [ newsAudience, setNewsAudience ] = useState<string[]>([])

    const handleAudienceChange = (sourceEvent: React.SyntheticEvent<HTMLElement, Event>, dropdownEvent: DropdownProps) => {
        setNewsAudience(dropdownEvent.value as string[])
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "subject") {
            setNewsSubject(event.target.value)
        }
    }

    const handleTextAreaChange = (event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        setNewsContent(data.value as string)
    }

    const handlePublishedChange = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setNewsPublished(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const cancelEdit = () => {
        props.history.push("/app/manage/news/existing")
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        const form = new FormData()
        form.set("subject", newsSubject)
        form.set("content", newsContent)
        form.set("published", ""+newsPublished)
        form.set("audience", newsAudience.join(","))

        axios.post<void>(`/api/manage/news/${editedNewsID}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => props.history.push("/app/manage/news/existing"))
            .catch(reason => handleNoAccess(reason, props.history))
    }

    const fetch = () => {
        const newsID = idFromLocation()
        setEditedNewsID(newsID)
        axios.get<NewsGetResponse>(`/api/manage/news/${newsID}`)
            .then(response => {
                setNewsSubject(response.data.News.Subject)
                setNewsContent(response.data.News.Content)
                setNewsPublished(response.data.News.Published)
                var audiences: string[] = []
                response.data.Audiences.forEach(audience => {
                    audiences.push(audience.Audience)
                })
                setNewsAudience(audiences)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const [initialized, setInitialized] = useState(false)
    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetch()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container text className="page">
            <Header as='h3'>{t("app-menu.administration")} / {t("manage-news.edit-header")}</Header>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>{t('manage-news.form.subject')}</label>
                    <Input fluid
                        placeholder={t('manage-news.form.html-info')}
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        onChange={handleInputChange}
                        value={newsSubject}
                        name="subject" id="subject"
                        maxLength="255" />
                </Form.Field>
                <Form.Field>
                    <label>{t('manage-news.form.content')}</label>
                    <TextArea fluid
                        placeholder={t('manage-news.form.html-info')}
                        label={{ icon: 'asterisk' }}
                        onChange={handleTextAreaChange}
                        value={newsContent}
                        name="content" id="content"
                        maxLength="15000" />
                </Form.Field>
                <Form.Field>
                    <label>{t('manage-news.form.audience')}</label>
                    <Dropdown clearable fluid multiple search selection
                        options={audienceOptions}
                        onChange={handleAudienceChange}
                        placeholder={t('manage-news.role-options.placeholder')}
                        value={newsAudience} />
                </Form.Field>
                <Form.Field>
                    <Checkbox toggle label={t('manage-news.form.published')}
                        onChange={handlePublishedChange}
                        checked={newsPublished} />
                </Form.Field>
                <Button basic color='green' type="submit">{t('common-buttons.update')}</Button>
                <Button basic color='red' onClick={() => cancelEdit()}>{t('common-buttons.cancel')}</Button>
            </Form>
        </Container>
    )
}
