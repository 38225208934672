import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Checkbox, CheckboxProps, Container, Dropdown, DropdownProps, Form, Header, Input, Message } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import {
    Redirect
  } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import { handleNoAccess, idFromLocation } from '../utils/utils';
import { PageProps, UserProfile } from '../types/types';

function UserEdit(props: PageProps) {

    const { t, i18n } = useTranslation();
    
    const [selectedUserID, setSelectedUserID] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [company, setCompany] = useState("")

    const [role, setRole] = useState("")
    const [telephone, setTelephone] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [postcode, setPostCode] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState(i18n.language)

    const [selectedEditUserRole, setSelectedEditUserRole] = useState("")
    const [selectedEditUserCanManageDatasets, setSelectedEditUserCanManageDatasets] = useState(false)
    const [selectedEditUserCanManageNews, setSelectedEditUserCanManageNews] = useState(false)
    const [selectedEditUserCanManageReports, setSelectedEditUserCanManageReports] = useState(false)
    const [selectedEditUserCanManageUsers, setSelectedEditUserCanManageUsers] = useState(false)

    const [errors, setErrors] = useState<string[]>([])

    const roleOptions = [
        {
            key: "is-employee",
            text: t('role-options.employee'),
            value: "is-employee"
        },
        {
            key: "is-partner",
            text: t('role-options.partner'),
            value: "is-partner"
        },
        {
            key: "is-wholesale",
            text: t('role-options.wholesale'),
            value: "is-wholesale"
        }
    ]

    const supportedCountries = [
        { key: 'dk', value: 'dk', flag: 'denmark', text: 'Danmark' },
        { key: 'de', value: 'de', flag: 'germany', text: 'Deutschland' },
        { key: 'uk', value: 'en', flag: 'uk', text: 'United Kingdom / Other' },
        { key: 'no', value: 'no', flag: 'no', text: 'Norge' },
        { key: 'se', value: 'se', flag: 'se', text: 'Sverige' },
        { key: 'fi', value: 'fi', flag: 'fi', text: 'Suomi' }
    ]

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const validationErrors = []
        const roles = []

        if (firstName.trim().length === 0) {
            validationErrors.push(t('user-edit.errors.missing-first-name'))
        }
        if (lastName.trim().length === 0) {
            validationErrors.push(t('user-edit.errors.missing-last-name'))
        }
        if (company.trim().length === 0) {
            validationErrors.push(t('user-edit.errors.missing-company'))
        }

        if (selectedEditUserRole.length > 0) {
            roles.push(selectedEditUserRole)
            roles.push("can-search")
        }
        if (selectedEditUserCanManageDatasets) {
            roles.push("can-manage-datasets")
        }
        if (selectedEditUserCanManageNews) {
            roles.push("can-manage-news")
        }
        if (selectedEditUserCanManageReports) {
            roles.push("can-manage-reports")
        }
        if (selectedEditUserCanManageUsers) {
            roles.push("can-manage-users")
        }

        setErrors(validationErrors)
        if (validationErrors.length > 0) {
            return
        }

        const form = new FormData()
        form.set("language", i18n.language)
        form.set("emailaddress", emailAddress.trim())
        form.set("firstname", firstName.trim())
        form.set("lastname", lastName.trim())
        form.set("company", company.trim())
        form.set("role", role.trim())
        form.set("telephone", telephone.trim())
        form.set("address1", address1.trim())
        form.set("address2", address2.trim())
        form.set("postcode", postcode.trim())
        form.set("city", city.trim())
        form.set("country", country.trim())
        form.set("roles", roles.join(","))

        axios.post<void>(`/api/manage/users/user/${selectedUserID}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => props.history.push("/app/manage/users/existing"))
            .catch(reason => {
                setErrors([t('user-edit.errors.request-failed', `HTTP status: ${reason.response.status}`)])
            })
    }

    const cancelAccountEdit = () => {
        props.history.push("/app/manage/users/existing")
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "firstname") {
            setFirstName(event.target.value)
        } else if (event.target.name === "lastname") {
            setLastName(event.target.value)
        } else if (event.target.name === "company") {
            setCompany(event.target.value)
        } else if (event.target.name === "function") {
            setRole(event.target.value)
        } else if (event.target.name === "telephone") {
            setTelephone(event.target.value)
        } else if (event.target.name === "address1") {
            setAddress1(event.target.value)
        } else if (event.target.name === "address2") {
            setAddress2(event.target.value)
        } else if (event.target.name === "postcode") {
            setPostCode(event.target.value)
        } else if (event.target.name === "city") {
            setCity(event.target.value)
        } else {
            console.error("sign-up: unsupported event target", event.target.value)
        }
    }

    const onCountryChange = (eventSource: React.SyntheticEvent<HTMLElement, Event>, eventData: DropdownProps) => {
        setCountry(eventData.value as string)
    }

    const updateSelectedEditUserAdminDatasets = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageDatasets(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminNews = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageNews(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminReports = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageReports(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }

    const updateSelectedEditUserAdminUsers = (sourceEvent: React.FormEvent<HTMLInputElement>, checkboxEvent: CheckboxProps) => {
        setSelectedEditUserCanManageUsers(checkboxEvent.checked === undefined ? false : checkboxEvent.checked)
    }
    
    const updateEditUserRole = (sourceEvent: React.SyntheticEvent<HTMLElement, Event>, eventData: DropdownProps) => {
        setSelectedEditUserRole(eventData.value as string)
    }

    const fetchUserForEdit = () => {
        const userID = idFromLocation()
        setSelectedUserID(userID)
        axios.get<UserProfile>(`/api/manage/users/user/${userID}`)
            .then(response => {
                const userInfo = response.data
                userInfo.RealmRoles.forEach(role => {
                    if (role === "is-employee" || role === "is-partner" || role === "is-wholesale") {
                        setSelectedEditUserRole(role)
                    }
                    if (role === "can-manage-datasets") {
                        setSelectedEditUserCanManageDatasets(true)
                    }
                    if (role === "can-manage-news") {
                        setSelectedEditUserCanManageNews(true)
                    }
                    if (role === "can-manage-reports") {
                        setSelectedEditUserCanManageReports(true)
                    }
                    if (role === "can-manage-users") {
                        setSelectedEditUserCanManageUsers(true)
                    }
                })
                setEmailAddress(userInfo.EmailAddress)
                setLastName(userInfo.LastName)
                setFirstName(userInfo.FirstName)
                setCompany(userInfo.Company)
                setRole(userInfo.Position)
                setTelephone(userInfo.Telephone)
                setAddress1(userInfo.Address1)
                setAddress2(userInfo.Address2)
                setPostCode(userInfo.Postcode)
                setCity(userInfo.City)
                setCountry(userInfo.Country)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchUserForEdit()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container text className="page withBottom">

            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.users-existing")} / {t('common-buttons.update')}</Header>

            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>{t('common-labels.email-address')}</label>
                    <strong>{emailAddress}</strong>
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.first-name')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.first-name')}
                        onChange={handleInputChange}
                        name="firstname" id="firstname"
                        value={firstName}
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.last-name')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.last-name')}
                        onChange={handleInputChange}
                        value={lastName}
                        name="lastname" id="lastname"
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.company')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.company')}
                        onChange={handleInputChange}
                        value={company}
                        name="company" id="company"
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.function')}</label>
                    <Input fluid
                        placeholder={t('common-labels.function')}
                        onChange={handleInputChange}
                        value={role}
                        name="function" id="function"
                        maxLength="50" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.telephone')}</label>
                    <Input fluid
                        placeholder={t('common-labels.telephone')}
                        onChange={handleInputChange}
                        value={telephone}
                        name="telephone" id="telephone"
                        maxLength="50" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.address1')}</label>
                    <Input fluid
                        placeholder={t('common-labels.address1')}
                        onChange={handleInputChange}
                        value={address1}
                        name="address1" id="address1"
                        maxLength="100"/>
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.address2')}</label>
                    <Input fluid
                        placeholder={t('common-labels.address2')}
                        onChange={handleInputChange}
                        value={address2}
                        name="address2" id="address2"
                        maxLength="100" />
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Field label={t('common-labels.postcode')} control='input'
                        onChange={handleInputChange}
                        value={postcode}
                        name="postcode" id ="postcode"
                        maxLength="20" />
                    <Form.Field label={t('common-labels.city')} control='input'
                        onChange={handleInputChange}
                        value={city}
                        name="city" id ="city"
                        maxLength="100" />
                </Form.Group>
                <Form.Field>
                    <label>{t('common-labels.country')}</label>
                    <Dropdown
                        selection
                        options={supportedCountries}
                        value={country}
                        onChange={onCountryChange} />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.select-role')}</label>
                    <Dropdown onChange={updateEditUserRole}
                        value={selectedEditUserRole}
                        placeholder={t('common-labels.select-role')} fluid selection
                        options={roleOptions} />
                </Form.Field>

                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-datasets')}
                        onChange={updateSelectedEditUserAdminDatasets}
                        checked={selectedEditUserCanManageDatasets} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-news')}
                        onChange={updateSelectedEditUserAdminNews}
                        checked={selectedEditUserCanManageNews} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-reports')}
                        onChange={updateSelectedEditUserAdminReports}
                        checked={selectedEditUserCanManageReports} />
                </Form.Field>
                <Form.Field>
                    <label></label>
                    <Checkbox toggle label={t('role-assignment.admin-users')}
                        onChange={updateSelectedEditUserAdminUsers}
                        checked={selectedEditUserCanManageUsers} />
                </Form.Field>

                <Button basic color='red' type="submit">{t('common-buttons.update')}</Button>
                <Button basic color='green' onClick={() => cancelAccountEdit()}>{t('common-buttons.cancel')}</Button>
            </Form>
            {errors.length === 0
                ? null
                : <Message
                    error
                    header={t('user-edit.errors.header')}
                    list={errors}
                /> }
        </Container>
    )

}

export default UserEdit;