import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Container, Form, Header, Table } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { getFormattedDate, handleNoAccess, idFromLocation, registerSupportedLocale} from '../utils/utils';
import { PageProps, ReportUsageDataResponse } from '../types/types';

export default function ReportUsageUser(props: PageProps) {

    const { t, i18n } = useTranslation();

    registerSupportedLocale()

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    const [userID, setUserID] = useState("")
    const [startDate, setStartDate] = useState(firstDay)
    const [endDate, setEndDate] = useState(lastDay)
    const [reportData, setReportData] = useState<ReportUsageDataResponse | null>(null)

    const loadReport = () => {
        axios.get<ReportUsageDataResponse>(`/api/manage/reports/usage/user/${userID}?from=${getFormattedDate(startDate)}&to=${getFormattedDate(endDate)}`)
            .then(response => {
                setReportData(response.data)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setUserID(idFromLocation())
            setInitialized(true)
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container text className="page">
            <Header as='h3'>{t("app-menu.reports-usage")}</Header>

            <Form>
                <Form.Group widths='equal'>
                    <Form.Field fluid label={t('reports.usage.labels.start-date')}
                        control={DatePicker}
                        selected={startDate}
                        onChange={(date: any) => setStartDate(date as Date)}
                        locale={i18n.language} />
                    <Form.Field fluid label={t('reports.usage.labels.end-date')}
                        control={DatePicker}
                        selected={endDate}
                        onChange={(date: any) => setEndDate(date as Date)}
                        locale={i18n.language} />
                </Form.Group>
                <Button onClick={() => loadReport()}>{t('common-buttons.load-report-data')}</Button>
            </Form>

            {reportData === null
                ? <></>
                : <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('common-headers.address-category')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('common-headers.count')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {reportData.categories.length === 0
                        ? <Table.Row><Table.Cell colspan={2}>{t('reports.usage.labels.no-data')}</Table.Cell></Table.Row>
                        : reportData.categories.map((category, i) => {
                            return <Table.Row>
                                <Table.Cell>{category.category}</Table.Cell>
                                <Table.Cell>{category.searches}</Table.Cell>
                            </Table.Row>
                        })
                    }
                        <Table.Row>
                            <Table.Cell> </Table.Cell>
                            <Table.Cell>{t('reports.usage.no-category-info')}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            }
            
        </Container>
    )

}
