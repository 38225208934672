import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, Container, Dropdown, Grid, Header, Icon, Modal, StrictDropdownItemProps, StrictDropdownProps } from 'semantic-ui-react'
import Moment from 'react-moment';
import 'semantic-ui-css/semantic.min.css'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Map as LeafletMap } from 'leaflet'
import 'leaflet/dist/leaflet.css';

import { useTranslation } from 'react-i18next';
import { Redirect, Link } from 'react-router-dom';

import { Authorization } from './types/features';
import { SearchResultAddressItem, SearchResult, PricingResults } from './types/search';
import { LegalInfo, NewsItem, PageProps } from './types/types';

interface Actionableitem {
    bandwidth: string,
    data: { [key: string]: { [key: string]: string; }; },
}

interface SelectionOption {
    [key: string]: any,
}

interface LatLngLiteral {
    lat: number;
    lng: number;
}

export default function Search(props: PageProps) {

    const { t, i18n } = useTranslation();

    const [ newsItems, setNewsItems ] = useState<NewsItem[]>([])

    const [cities, setCities] = useState<SelectionOption[]>([])
    const [addressItems, setAddressItems] = useState<SelectionOption[]>([])

    const [selectedAddress, setSelectedAddress] = useState<SearchResultAddressItem | null>(null)
    const [selectedAddressPosition, setSelectedAddressPosition] = useState<LatLngLiteral>({lat: 0, lng: 0})
    const [mapRef, setMapRef] = useState<LeafletMap | undefined>(undefined)

    const [pricingData, setPricingData] = useState<Actionableitem[]>([])
    const [pricingDataAddressID, setPricingDataAddressID] = useState("")

    const [selectedCity, setSelectedCity] = useState<SearchResultAddressItem | null>(null)
    const [searchGeneration, setSearchGeneration] = useState(0)

    const [legalInfo, setLegalInfo] = useState<LegalInfo | null>(null)

    const [isEmployee, setIsEmployee] = useState(false)

    const [searchResultsEmailSent, setSearchResultsEmailSent] = useState(false)

    const onCityChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: StrictDropdownProps) => {
        setSelectedCity(data.value as any as SearchResultAddressItem)
    }

    const storeMapRef = (e: LeafletMap) => {
        setMapRef(e)
    }

    const onCityTextChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: StrictDropdownProps) => {
        if (data.searchQuery !== undefined && data.searchQuery.length > 2) {
            const queryValue = data.searchQuery
            setAddressItems([])
            axios.post<SearchResult>("/api/search", {mode: "city-or-code", input: queryValue})
                .then(result => {
                    var cities: SelectionOption[] = []
                    result.data.results.forEach(item => {
                        let label = item.City
                        if (item.Postcode !== "") {
                            label = label + ` (${item.Postcode})`
                        }
                        return cities.push({"key": item.City, "text": label, "value": item})
                    })
                    setCities(cities)
                })
                .catch(err => console.log(err))
        }
    }

    const onStreetTextChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: StrictDropdownProps) => {
        if (data.searchQuery !== undefined && data.searchQuery.length > 2) {
            const queryValue = data.searchQuery
            let searchCriteria = ""
            if (selectedCity !== null) {
                searchCriteria = selectedCity.City
                if (selectedCity.Postcode !== "") {
                    searchCriteria = searchCriteria + ";" + selectedCity.Postcode
                }
            }
            setAddressItems([])
            setSearchGeneration(searchGeneration + 1)
            axios.post<SearchResult>("/api/search", {mode: "street", rid: "rid-"+searchGeneration, input: queryValue, opt_city: searchCriteria})
                .then(result => {
                    if (result.data.rid === "rid-"+searchGeneration) {
                        var addresses: SelectionOption[] = []
                        result.data.results.forEach(item => addresses.push({
                            "key": item.Addressid,
                            "text": `${item.Street} ${item.Housenumber}, ${item.Postcode} ${item.City}`,
                            "value": item
                        }))
                        setAddressItems(addresses)
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const onAddressSelectionChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: StrictDropdownProps) => {
        const value = data.value as any as SearchResultAddressItem
        setSelectedAddress(value)

        if (mapRef !== undefined) {
            mapRef.setView({ lat: Number.parseFloat(value.Gpsy), lng: Number.parseFloat(value.Gpsx) })
        }

        setSelectedAddressPosition({ lat: Number.parseFloat(value.Gpsy), lng: Number.parseFloat(value.Gpsx) })
        axios.post<PricingResults>("/api/search", {mode: "get-pricing", input: value.Addressid})
            .then(result => {
                const normalisedItems: { [key: string]: { [key: string]: { [key: string]: string; }; }; } = {}
                result.data.results.map(item => {
                    if (normalisedItems[item.bandwidth] === undefined) {
                        normalisedItems[item.bandwidth] = {}
                    }
                    if (normalisedItems[item.bandwidth][item.type] === undefined) {
                        normalisedItems[item.bandwidth][item.type] = {}
                    }
                    if (normalisedItems[item.bandwidth][item.type][item.duration] === undefined) {
                        normalisedItems[item.bandwidth][item.type][item.duration] = item.value
                    }
                })
                const actionableItems: Actionableitem[] = []
                for (const [key, value] of Object.entries(normalisedItems)) {
                    actionableItems.push({'bandwidth': key, 'data': value})
                }
                setPricingDataAddressID(result.data.address_id)
                setPricingData(actionableItems)

                if (legalInfo === null) {
                    axios.get<LegalInfo>(`/api/i18n/legal/${i18n.language}`)
                        .then(result => setLegalInfo(result.data))
                        .catch(err => console.log(err))
                }

            })
            .catch(err => console.log(err))
    }

    const openOSMWindow = () => {
        window.open(`http://www.openstreetmap.org/?mlat=${selectedAddressPosition.lat}&mlon=${selectedAddressPosition.lng}&zoom=14`, "_blank")
    }

    const citySearchFn = (options: StrictDropdownItemProps[], query: string) => {
        return options.filter((opt) => {
            if (opt.value !== undefined) {
                const addressValue = opt.value as any as SearchResultAddressItem
                return addressValue.City.toLowerCase().indexOf(query.toLowerCase()) === 0 || addressValue.Postcode.indexOf(query) === 0
            }
            return false
        })
      }
    
    const formatPriceValue = (input: string): string => {
        if (input.indexOf("EUR") > -1) {
            return input.replace("EUR", "€")
        }
        return input
    }

    const [ initialized, setInitialized ] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)

            axios.get<Authorization>("/api/features")
                .then(response => {
                    response.data.permissions.forEach(permission => {
                        if (permission.scopes !== null) {
                            permission.scopes.forEach(scope => {
                                if (scope === "globalconnect:pricing:employee") {
                                    setIsEmployee(true)
                                }
                            })
                        }
                    })
                })
                .catch(e => console.log(e))
            
            axios.get<NewsItem[]>("/api/read-news")
                .then(response => setNewsItems(response.data))
                .catch(e => console.log(e))
        }
    })

    useEffect(() => {
        const L = require("leaflet");
    
        delete L.Icon.Default.prototype._getIconUrl;
    
        L.Icon.Default.mergeOptions({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("leaflet/dist/images/marker-icon.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
      }, [])

    const sendDataByEmail = () => {
        axios.get<void>(`/api/search/email/${pricingDataAddressID}?language=${i18n.language}`)
            .then(_ => setSearchResultsEmailSent(true))
            .catch(e => console.log(e))
    }

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container className="page">
            
            <Container className="searchContainer">
                <Header>{t('search.title')}</Header>
                <Grid columns={2} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Dropdown
                                placeholder={t('common-labels.city')}
                                fluid
                                selection
                                clearable
                                onChange={onCityChange}
                                onSearchChange={onCityTextChange}
                                options={cities}
                                search={citySearchFn}
                                noResultsMessage={t('search.no-results')}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Dropdown
                                placeholder={t('common-labels.street')}
                                fluid
                                search
                                selection
                                onChange={onAddressSelectionChange}
                                onSearchChange={onStreetTextChange}
                                options={addressItems}
                                noResultsMessage={t('search.no-results')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <span className="nothingFoundInfo">{t('search.nothing-found-info')}</span>
            </Container>
            <Container className="searchContainer searchResultsContainer">
            { selectedAddress === null
                ? <></>
                : <>
                    <Header as='h2'>{selectedAddress.Street} {selectedAddress.Housenumber}, {selectedAddress.Postcode} {selectedAddress.City}</Header>
                    <span className='addressCategory'><strong>{t('search.address-data.category')}:</strong> {selectedAddress.Category}</span>
                    { pricingData.length === 0
                        ? <p>{t('search.address-data.no-data')}</p>
                        : <Container>
                            <Container textAlign='center'>
                                <Button className='buttonSearchEmail' basic icon color='green'
                                    labelPosition='right'
                                    onClick={sendDataByEmail}>
                                    <Icon name='paper plane' />
                                    {t('search.email.send')}
                                </Button>
                            </Container>
                            <Container style={{marginTop: "-46px"}}>
                                <Button icon style={{zIndex: 1000, position: "relative", left: "-50px", top: "46px"}}
                                    onClick={openOSMWindow}>
                                    <Icon name="external alternate" />
                                </Button>
                                <MapContainer whenCreated={e => storeMapRef(e)}
                                    center={selectedAddressPosition} zoom={14}
                                    scrollWheelZoom={false} style={{ height: "400px", margin: "10px 0 10px 0" }}>
                                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    <Marker position={selectedAddressPosition}>
                                        <Popup />
                                    </Marker>
                                </MapContainer>
                            </Container>
                            <Card.Group>
                            {pricingData.map(pricingDataItem => {
                                return <Card>
                                    <Card.Content>
                                        {
                                            pricingDataItem.bandwidth === "-"
                                                ? null
                                                : <Card.Header>{pricingDataItem.bandwidth}</Card.Header>

                                        }
                                        <Card.Meta>OTC</Card.Meta>
                                        <Card.Description>
                                            <Grid columns={2} textAlign={"center"}>
                                                <Grid.Row>
                                                    <Grid.Column className="durationDescription">36&nbsp;{t('search.months')}</Grid.Column>
                                                    <Grid.Column className="durationDescription">60&nbsp;{t('search.months')}</Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Description>
                                        <Card.Description>
                                            <Grid columns={2} textAlign={"center"}>
                                                <Grid.Row>
                                                    <Grid.Column>{formatPriceValue(pricingDataItem.data.OTC['36m'])}</Grid.Column>
                                                    <Grid.Column>{formatPriceValue(pricingDataItem.data.OTC['60m'])}</Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Description>
                                        <Card.Meta>
                                            <Container>&nbsp;</Container>
                                        </Card.Meta>
                                        <Card.Meta>MRC</Card.Meta>
                                        <Card.Description>
                                            <Grid columns={2} textAlign={"center"}>
                                                <Grid.Row>
                                                    <Grid.Column className="durationDescription">36&nbsp;{t('search.months')}</Grid.Column>
                                                    <Grid.Column className="durationDescription">60&nbsp;{t('search.months')}</Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Description>
                                        <Card.Description>
                                            <Grid columns={2} textAlign={"center"}>
                                                <Grid.Row>
                                                    <Grid.Column>{formatPriceValue(pricingDataItem.data.MRC['36m'])}</Grid.Column>
                                                    <Grid.Column>{formatPriceValue(pricingDataItem.data.MRC['60m'])}</Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            })}
                            </Card.Group>
                        </Container>
                    }
                    {legalInfo === null
                        ? <></>
                        : <>
                            <Header as="h2">{legalInfo.Header}</Header>
                            <ol>
                                {legalInfo.Lines.map((line, i) => {
                                    return <li>{line}</li>
                                })}
                                {isEmployee
                                    ? <li>Bei den nachfolgenden Entgelten handelt es sich um Preisuntergrenzen. Für dem Verkaufspreis müssen die bandbreitenabhängigen Mindestpreise berücksichtigt werden. Die Mindestpreise und weitere Informationen findet Ihr auf <a href="https://confluence.cotools.net/display/OR/NearNet+-+Tool">Confluence.</a></li>
                                    : <></>
                                }
                            </ol>
                        </>
                    }
                </>
            }
            {newsItems.length > 0
                ? <>
                    <Header as="h2">News</Header>
                    <ol>
                            {newsItems.map((newsItem, i) => {
                                return <li>
                                    <strong><Link to={"/app/read-news/"+newsItem.NewsID}>{newsItem.Subject}</Link></strong>, {t('manage-news.overview.published-at')}: <Moment format="YYYY-MM-DD HH:mm">{newsItem.CreatedAt}</Moment>
                                </li>
                            })}
                    </ol>
                    </>
                : <></>
            }
            </Container>
            <Modal open={searchResultsEmailSent}
                onClose={() => { setSearchResultsEmailSent(false) }}
                header={t('search.email.send')}
                content={t('search.email.confirmation')}
                actions={[{ key: 'done', content: 'OK', positive: true }]} />
        </Container>
    )
}
