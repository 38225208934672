import React from 'react';
import { Link } from "react-router-dom";
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

export default function SignedUp() {
    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    return (
        <Container text className="page"
            textAlign="center">
            <Header as='h1'>{t('signed-up.title')}</Header>
            <p>{t('signed-up.body')}</p>
            <Link to="/">ok!</Link>
        </Container>
    )
}