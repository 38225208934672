import React, { useState } from 'react';
import axios from 'axios';

import { Button, Container, Form, Input, Message } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import { useTranslation } from 'react-i18next';
import { PageSignInProps } from './types/types';

export default function SignIn (props: PageSignInProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    
    const [username, setUserName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState("")

    const [errors, setErrors] = useState<string[]>([])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const validationErrors = []
        if (emailAddress.trim() === "") {
            validationErrors.push(t('sign-in.errors.missing-email'))
        } else {
            const addressBits = emailAddress.split("@")
            if (addressBits.length !== 2) {
                validationErrors.push(t('sign-in.errors.email-invalid-format'))
            } else {
                if (addressBits[0].trim().length === 0 || addressBits[1].trim().length === 0) {
                    validationErrors.push(t('sign-in.errors.email-invalid-format'))
                }
            }
        }
        if (password.length === 0) {
            validationErrors.push(t('sign-in.errors.missing-password'))
        }

        setErrors(validationErrors)
        if (validationErrors.length > 0) {
            return
        }

        const form = new FormData()
        form.set("username", username)
        form.set("emailaddress", emailAddress.trim())
        form.set('password', password)
        axios.post<string>('/api/signin', form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            props.callback(response.data)
            props.history.push("/app/search")
        }).catch(reason => {
            // TODO: handleNoAccess
            if (reason.response.status === 401) {
                setErrors([t('sign-in.errors.invalid-credentials')])
            } else {
                setErrors([t('sign-in.errors.request-failed', `HTTP status: ${reason.response.status}`)])
            }
        })
    }
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if ( event.target.name === "username" ) {
            setUserName(event.target.value)
        } else if ( event.target.name === "emailaddress" ) {
            setEmailAddress(event.target.value)
        } else if ( event.target.name === "password" ) {
            setPassword(event.target.value)
        } else {
            console.error("sign-in: unknown event target", event.target.name)
        }
    }
  
    return (
        <Container text className="page">
            <Form onSubmit={handleSubmit}>
                <Form.Field className="special">
                    <label>Desired Useername</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder="Desired username"
                        onChange={handleInputChange}
                        name="username" id="username" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.email-address')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.email-address-placeholder')}
                        onChange={handleInputChange}
                        name="emailaddress" id="emailaddress" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.password')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password')}
                        onChange={handleInputChange}
                        name="password" id="password" />
                </Form.Field>
                <Button type="submit">{t('common-buttons.sign-in')}</Button>
            </Form>
            {errors.length === 0
                ? null
                : <Message
                    error
                    header={t('sign-in.errors.header')}
                    list={errors}
                /> }
        </Container>
     )
}
