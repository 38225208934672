import { History } from 'history'

export enum CharacterClass {
    DIGIT,
    LOWERCASE,
    SPECIAL,
    UPPERCASE,
}

export interface DataSet {
    CreatedAt: string,
    ID: string,
    ImportedBy: string,
    RowsErrors: number,
    RowsSuccess: number,
}
export interface InsecureHTML {
    __html: string,
}

export interface LegalInfo {
	Header: string
	Lines: string[],
}

export interface LoginAudit {
    CreatedAt: string,
}

export interface NewsGetResponse {
    Audiences: NewsItemAudience[],
    News: NewsItem,
}

export interface NewsListResponse {
    Items: NewsGetResponse[],
}

export interface NewsItem {
    CreatedAt: string,
    CreatedBy: string,
    Content: string,
    ID: string,
    NewsID: string,
    Subject: string,
    Published: boolean,
}

export interface NewsItemAudience {
    Audience: string,
    NewsID: string,
}

export interface PageProps {
    history: History,
    sessionID: string,
}

export interface HistoryOnlyProps {
    history: History,
}

export interface PageSignInProps {
    callback: (input: string) => void,
    history: History,
}

export interface PageSignOutProps {
    callback: () => void,
    sessionID: string,
}

export interface PasswordPolicyApp {
    policy: PasswordPolicyResponse,
    requirements: string[],
}

export interface PasswordPolicyResponse {
    digits: number,
	lowerCase: number,
	upperCase: number,
	special: number,
	minimumLength: number,
	notUsername: boolean,
}

interface UsageDataItem {
    category: string,
	searches: number,
}

export interface ReportUsageDataResponse {
    categories: UsageDataItem[],
    end_date: string,
	start_date: string,
}

export interface SupportedCountry {
    key: string,
    value: string,
    flag: string,
    text: string,
}

export interface UserActivation {
    CreatedAt: string,
	UserID:         string,
	EmailAddress:   string,
	FirstName: string,
	LastName: string,
	Company: string,
	StatusVerified: string,
}

export interface UserProfile {
    ActivatedAt: string,
    Address1: string,
    Address2: string,
    City: string,
    Company: string,
    Country: string,
    EmailAddress: string,
    EmailVerified: boolean,
    FirstName: string,
    ID: string,
    Language: string,
    LastLoginAudit: LoginAudit;
    LastName: string,
    Position: string,
    Postcode: string,
    RealmRoles: string[],
    Telephone: string,
}

export interface UserSearchResult {
    SearchID: string,
    Accounts: UserProfile[],
}