import React from 'react';
import {
    Link
} from "react-router-dom";

import { Container, Header } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next';
import { PageProps } from '../types/types';

export default function ChangedPassword(props: PageProps) {
    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    return (
        <Container text className="page" textAlign="center">
            <Header as='h1'>{t('profile.updated.password.title')}</Header>
            <p>{t('profile.updated.password.body')}</p>
            <Link to="/app/profile/view">ok!</Link>
        </Container>
    )
}