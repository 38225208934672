import React, { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { Button, Container, Form } from 'semantic-ui-react'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

function DatasetUpload(props) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [separator, setSeparator] = useState(";")
    const [dataStarts, setDataStarts] = useState("3")
    const [rowItems, setRowItems] = useState("57")

    const handleInputChange = (event) => {
        if ( event.target.name === "separator" ) {
            setSeparator(event.target.value)
        } else if ( event.target.name === "data-starts-at" ) {
            setDataStarts(event.target.value)
        } else if ( event.target.name === "row-items" ) {
            setRowItems(event.target.value)
        } else {
            console.error("import: unknown event target", event.target.name)
        }
    }
    
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: ['text/csv', 'application/vnd.ms-excel'],
        multiple: false,
        onDrop: function(acceptedFiles) {
            acceptedFiles.forEach(file => {
                setUploadFile(file)
                setUploadState("file-selected")
            })
        },
    });

    const checkUploadProgress = (importID, cb) => {
        axios.get(`/api/manage/datasets/imports/${importID}`)
            .then(response => {
                cb(response)
            })
    }

    const validateUpload = () => {

        if (separator.trim().length !== 1) {
            alert(t("dataset-upload.errors.invalid-separator"))
            return
        }

        if (isNaN(parseInt(dataStarts))) {
            alert(t("dataset-upload.errors.invalid-data-starts-at"))
            return
        } else {
            if (parseInt(dataStarts) < 0) {
                alert(t("dataset-upload.errors.too-low-data-starts-at"))
                return
            }
        }

        if (isNaN(parseInt(rowItems))) {
            alert(t("dataset-upload.errors.invalid-row-items"))
            return
        } else {
            if (parseInt(rowItems) < 1) {
                alert(t("dataset-upload.errors.too-low-row-items"))
                return
            }
        }

        var reader = new FileReader();
        reader.addEventListener("loadend", function(event) {
            let fileData = event.target.result
            let lineNumber = 0
            let uploadableLines = 0
            let firstDataRow = parseInt(dataStarts)
            let expectedColumns = parseInt(rowItems)
            let canContinue = true
            while (fileData.length > 0) {
                // get line of data:
                let newLinePos = fileData.indexOf("\n")
                if (newLinePos === -1) {
                    alert(format(t('dataset-upload.errors.invalid-data-format'), lineNumber))
                    canContinue = false
                    break
                }
                let line = fileData.substring(0, newLinePos)
                fileData = fileData.substring(newLinePos+1, fileData.length)
                // increase the line number:
                lineNumber = lineNumber + 1
                if (lineNumber >= firstDataRow) {
                    // if we are at the row of data start or further:
                    const rowData = line.split(separator.trim())
                    if (rowData.length < expectedColumns) {
                        alert(format(t('dataset-upload.errors.invalid-line-format'), lineNumber, rowData.length, expectedColumns))
                        canContinue = false
                        break
                    }
                    uploadableLines = uploadableLines + 1
                }
            }
            if (canContinue) {
                const confirmed = window.confirm(format(t('dataset-upload.progress.upload-confirm'), uploadableLines))
                if (confirmed) {
                    startUpload()
                }
            }
        });
        reader.readAsText(uploadFile);
    }

    const startUpload = () => {
        axios.post("/api/manage/datasets/imports").then(response => {

            const data = new FormData();
            data.set('separator', separator)
            data.set('data-starts-at', dataStarts)
            data.set('row-items', rowItems)
            data.append('csv-data', uploadFile, uploadFile.name)

            setUploadProgress("0%")
            setShowSelector(false)
            setUploadState("in-progress")
            var f = viewUploadInProgress(response.data.ID)

            axios.post(`/api/manage/datasets/imports/${response.data.ID}`, data, {
                headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        setUploadProgress((progressEvent.loaded/progressEvent.total*100).toFixed(2) + "%")
                        if (progressEvent.loaded/progressEvent.total === 1) {
                            setTimeout(() => f(), 0)
                        }
                    }
                }
            }).then(response => {
                console.error("new upload fully completed")
            }).catch(reason => {
                console.error("Upload failed", reason)
            })

        }).catch(reason => {
            console.error("Upload failed", reason)
        })
    }

    const uploadCompleted = () => {
        setUploadState("no-file-selected")
        setShowSelector(true)
        props.onUploadComplete()
    }

    const recoverImportInProgress = () => {
        axios.get("/api/manage/datasets/in-progress/list")
            .then(response => {
                if (response.data.length > 0) {
                    setShowSelector(false)
                    setUploadState("in-progress")
                    setUploadProgress("100%")
                    viewUploadInProgress(response.data[0].ID)()
                }
            })
            .catch(e => console.log(e))
    }

    const viewUploadInProgress = (uploadID) => {
        var shouldRefetchCount = true
        var f = function() {
            checkUploadProgress(uploadID, countResponse => {
                setUploadedRows(countResponse.data)
                axios.get("/api/manage/datasets/in-progress/list")
                    .then(response => {
                        var found = false
                        response.data.forEach(upload => {
                            if (upload.ID === uploadID) {
                                found = true
                            }
                        })
                        if (!found) {
                            uploadCompleted()
                        } else {
                            console.log(`found upload '${uploadID}' in progress, continuing`)
                            setTimeout(() => {
                                if (shouldRefetchCount) f()
                            }, 1000)
                        }
                    })
            })
        }
        return f
    }

    
    var [showSelector, setShowSelector] = useState(true)
    var [uploadState, setUploadState] = useState("no-file-selected")
    var [uploadProgress, setUploadProgress] = useState("n/a")
    var [uploadedRows, setUploadedRows] = useState("n/a")
    var [uploadFile, setUploadFile] = useState(null)

    /*
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
        {file.path} - {file.size} bytes
        </li>
    ))
    */

    const fileRejectionItems = fileRejections.map(( file, errors ) => (
        <aside><h4>{t('dataset-upload.errors.file-rejected')}{errors}</h4></aside>
    ))


    var [initialized, setInitialized] = useState(false)
    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            recoverImportInProgress()
        }
    })

    return (
        <Container text className="page">
            <Container fluid className={showSelector ? null : "invisible"}>
                <Container fluid {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Button fluid>{t('dataset-upload.buttons.select')}</Button>
                    { fileRejectionItems.length > 0
                        ? fileRejectionItems
                        : null
                    }
                </Container>
                { uploadState === "file-selected"
                    ? <Container fluid className="page">
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Input fluid
                                    label={t('dataset-upload.form.separator')}
                                    value={separator}
                                    onChange={handleInputChange}
                                    name="separator" id="separator" />
                                <Form.Input fluid
                                    label={t('dataset-upload.form.num-rows-skip')}
                                    value={dataStarts}
                                    onChange={handleInputChange}
                                    name="data-starts-at" id="data-starts-at" />
                                <Form.Input fluid
                                    label={t('dataset-upload.form.row-items')}
                                    value={rowItems}
                                    onChange={handleInputChange}
                                    name="row-items" id="row-items" />
                            </Form.Group>
                        </Form>
                        <Button fluid
                            onClick={() => validateUpload()}>{t('dataset-upload.buttons.import')} {uploadFile.name}</Button>
                    </Container>
                    : null }
            </Container>
            { !showSelector
                ? <Container fluid className="page">
                    <p><strong>{t('dataset-upload.progress.header')}</strong></p>
                    <p>{t('dataset-upload.progress.upload')}: {uploadProgress}, {t('dataset-upload.progress.rows')}: {uploadedRows}</p>
                </Container>
                : null }
        </Container>
    );
}

export default DatasetUpload;