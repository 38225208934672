import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Container, Grid, Header } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import { Redirect, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { PageProps, SupportedCountry, UserProfile } from '../types/types';

export default function ProfileView(props: PageProps) {

    const { t, i18n } = useTranslation();
    
    const [emailAddress, setEmailAddress] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [company, setCompany] = useState("")

    const [role, setRole] = useState("")
    const [telephone, setTelephone] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [postcode, setPostCode] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState(i18n.language)

    const supportedCountries: SupportedCountry[] = [
        { key: 'dk', value: 'dk', flag: 'denmark', text: 'Danmark' },
        { key: 'de', value: 'de', flag: 'germany', text: 'Deutschland' },
        { key: 'uk', value: 'en', flag: 'uk', text: 'United Kingdom / Other' },
        { key: 'no', value: 'no', flag: 'no', text: 'Norge' },
        { key: 'se', value: 'se', flag: 'se', text: 'Sverige' },
        { key: 'fi', value: 'fi', flag: 'fi', text: 'Suomi' }
    ]

    const getCountryByCode = (code: string): string => {
        let countryName = ""
        supportedCountries.forEach(sc => {
            if (sc.key === code) {
                countryName = sc.text
            }
        })
        return countryName
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            axios.get<UserProfile>("/api/profile")
                .then(response => {
                    setEmailAddress(response.data.EmailAddress)
                    setLastName(response.data.LastName)
                    setFirstName(response.data.FirstName)
                    setCompany(response.data.Company)
                    setRole(response.data.Position)
                    setTelephone(response.data.Telephone)
                    setAddress1(response.data.Address1)
                    setAddress2(response.data.Address2)
                    setPostCode(response.data.Postcode)
                    setCity(response.data.City)
                    setCountry(response.data.Country)
                })
                .catch(err => console.log(err))
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }

    return (
        <Container text className="page">
            <Header as='h3'>{t("app-menu.profile")}</Header>
            <Grid celled>
                
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.email-address')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{emailAddress}</strong></Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.password')}</label></Grid.Column>
                    <Grid.Column width={11}><Link to="/app/profile/password-change">{t('common-buttons.change-password')}</Link></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.first-name')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{firstName}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.last-name')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{lastName}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.company')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{company}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.function')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{role}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.telephone')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{telephone}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.address1')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{address1}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.address2')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{address2}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.postcode')}, {t('common-labels.city')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{postcode}</strong> <strong>{city}</strong></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}><label>{t('common-labels.country')}</label></Grid.Column>
                    <Grid.Column width={11}><strong>{getCountryByCode(country)}</strong></Grid.Column>
                </Grid.Row>

            </Grid>
        </Container>
    )

}