import React, { useState, useEffect } from 'react';
import { Button, Container, Header, Label, Table } from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';

import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';
import { DataSet, PageProps } from '../types/types';
import { handleNoAccess } from '../utils/utils';

export default function ManageDatasetsExisting(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [dataSets, setDataSets] = useState<DataSet[]>([])

    const deleteImport = (dataSet: DataSet) => {
        if (window.confirm(t('manage-datasets.delete-confirm'))) {
            axios.delete<void>(`/api/manage/datasets/imports/${dataSet.ID}`)
                .then(_ => fetchImports())
                .catch(e => handleNoAccess(e, props.history))
        }
    }

    const fetchImports = () => {
        axios.get<DataSet[]>("/api/manage/datasets/imports")
            .then(response => {
                setDataSets(response.data)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchImports()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page">

            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.datasets-existing")}</Header>

            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-datasets.column-headers.created-at')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-datasets.column-headers.imported-by')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-datasets.column-headers.status')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {dataSets.length === 0
                    ? <Table.Row><Table.Cell colspan={6}>{t('manage-datasets.no-data')}</Table.Cell></Table.Row>
                    : dataSets.map((dataSet, i) => {
                        return <Table.Row>
                            <Table.Cell>{dataSet.ID}</Table.Cell>
                            <Table.Cell><Moment format="YYYY-MM-DD HH:mm:ss">{dataSet.CreatedAt}</Moment></Table.Cell>
                            <Table.Cell>{dataSet.ImportedBy}</Table.Cell>
                            <Table.Cell>
                                <Label color='green'>{dataSet.RowsSuccess}</Label> / <Label color='red'>{dataSet.RowsErrors}</Label>
                            </Table.Cell>
                            <Table.Cell>
                                <Button color="red" onClick={() => deleteImport(dataSet)}>{t('common-buttons.delete')}</Button>
                            </Table.Cell>
                        </Table.Row>
                    })
                }
            </Table>
        </Container>
    )
}
