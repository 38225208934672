import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Container, Dropdown, DropdownProps, Form, Input, Message } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import { useTranslation } from 'react-i18next';

import PasswordStrengthBar from 'react-password-strength-bar';

import { HistoryOnlyProps, PasswordPolicyApp, PasswordPolicyResponse } from './types/types'
import { countDigits, countLowerCase, countSpecial, countUpperCase } from './utils/passwords'

export default function SignUp(props: HistoryOnlyProps) {

    const { t, i18n } = useTranslation();
    
    const [username, setUserName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [company, setCompany] = useState("")

    const [role, setRole] = useState("")
    const [telephone, setTelephone] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [postcode, setPostCode] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState(i18n.language)

    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [errors, setErrors] = useState<string[]>([])
    const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicyApp | null>(null)

    const supportedCountries = [
        { key: 'dk', value: 'dk', flag: 'denmark', text: 'Danmark' },
        { key: 'de', value: 'de', flag: 'germany', text: 'Deutschland' },
        { key: 'uk', value: 'en', flag: 'uk', text: 'United Kingdom / Other' },
        { key: 'no', value: 'no', flag: 'no', text: 'Norge' },
        { key: 'se', value: 'se', flag: 'se', text: 'Sverige' },
        { key: 'fi', value: 'fi', flag: 'fi', text: 'Suomi' }
      ]

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const validationErrors = []
        if (emailAddress.trim() === "") {
            validationErrors.push(t('sign-up.errors.missing-email'))
        } else {
            const addressBits = emailAddress.split("@")
            if (addressBits.length !== 2) {
                validationErrors.push(t('sign-up.errors.email-invalid-format'))
            } else {
                if (addressBits[0].trim().length === 0 || addressBits[1].trim().length === 0) {
                    validationErrors.push(t('sign-up.errors.email-invalid-format'))
                }
            }
        }
        if (firstName.trim().length === 0) {
            validationErrors.push(t('sign-up.errors.missing-first-name'))
        }
        if (lastName.trim().length === 0) {
            validationErrors.push(t('sign-up.errors.missing-last-name'))
        }
        if (company.trim().length === 0) {
            validationErrors.push(t('sign-up.errors.missing-company'))
        }


        // password validation, very simple, back end tests for more:
        if (passwordPolicy !== null) {
            if (passwordPolicy.policy.minimumLength > 0) {
                if (password.length < passwordPolicy.policy.minimumLength) {
                    validationErrors.push(t('sign-up.errors.password-too-short'))
                }
            }
            if (passwordPolicy.policy.digits > 0) {
                if (countDigits(password) < passwordPolicy.policy.digits) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-digits'))
                }
            }
            if (passwordPolicy.policy.lowerCase > 0) {
                if (countLowerCase(password) < passwordPolicy.policy.lowerCase) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-lowerCase'))
                }
            }
            if (passwordPolicy.policy.upperCase > 0) {
                if (countUpperCase(password) < passwordPolicy.policy.upperCase) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-upperCase'))
                }
            }
            if (passwordPolicy.policy.special > 0) {
                if (countSpecial(password) < passwordPolicy.policy.special) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-special'))
                }
            }
        } else {
            if (password.length === 0) {
                validationErrors.push(t('sign-up.errors.missing-password'))
            }
        }

        if (password !== passwordConfirmation) {
            validationErrors.push(t('sign-up.errors.password-confirmation-invalid'))
        }

        setErrors(validationErrors)
        if (validationErrors.length > 0) {
            return
        }

        const form = new FormData()
        form.set("username", username)
        form.set("language", i18n.language)
        form.set("emailaddress", emailAddress.trim())
        form.set("firstname", firstName.trim())
        form.set("lastname", lastName.trim())
        form.set("company", company.trim())
        form.set("role", role.trim())
        form.set("telephone", telephone.trim())
        form.set("address1", address1.trim())
        form.set("address2", address2.trim())
        form.set("postcode", postcode.trim())
        form.set("city", city.trim())
        form.set("country", country.trim())
        form.set('password', password)

        axios.post<void>('/api/signup', form, {
            headers: { 'Content-Type': 'multipart/form-data' } })
            .then(_ => props.history.push("/app/signed/up"))
            .catch(reason => {
                if (reason.response.status === 409) {
                    setErrors([t('sign-up.errors.conflict')])
                    return
                }
                if (reason.response.data.indexOf("invalidPassword") > -1) {
                    setErrors([t('sign-up.errors.invalid-password')])
                    return
                }
                setErrors([t('sign-up.errors.request-failed', `HTTP status: ${reason.response.status}`)])
            })
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "username") {
            setUserName(event.target.value)
        } else if (event.target.name === "emailaddress") {
            setEmailAddress(event.target.value)
        } else if (event.target.name === "firstname") {
            setFirstName(event.target.value)
        } else if (event.target.name === "lastname") {
            setLastName(event.target.value)
        } else if (event.target.name === "company") {
            setCompany(event.target.value)
        } else if (event.target.name === "function") {
            setRole(event.target.value)
        } else if (event.target.name === "telephone") {
            setTelephone(event.target.value)
        } else if (event.target.name === "address1") {
            setAddress1(event.target.value)
        } else if (event.target.name === "address2") {
            setAddress2(event.target.value)
        } else if (event.target.name === "postcode") {
            setPostCode(event.target.value)
        } else if (event.target.name === "city") {
            setCity(event.target.value)
        } else if (event.target.name === "password") {
            setPassword(event.target.value)
        } else if (event.target.name === "passwordConfirmation") {
            setPasswordConfirmation(event.target.value)
        } else {
            console.error("sign-up: unsupported event target", event.target.value)
        }
    }

    const onCountryChange = (eventSource: React.SyntheticEvent<HTMLElement, Event>, eventData: DropdownProps) => {
        setCountry(eventData.value as string)
    }

    useEffect(() => {
        if (passwordPolicy === null) {
            axios.get<PasswordPolicyResponse>("/api/password-policy")
                .then(response => {
                    var requirements = []
                    if (response.data.minimumLength > 0) {
                        requirements.push(`${t('password-policy.minimum-length')}: ${response.data.minimumLength}`)
                    }
                    if (response.data.digits > 0) {
                        requirements.push(`${t('password-policy.digits')}: ${response.data.digits}`)
                    }
                    if (response.data.lowerCase > 0) {
                        requirements.push(`${t('password-policy.lower-case')}: ${response.data.lowerCase}`)
                    }
                    if (response.data.upperCase > 0) {
                        requirements.push(`${t('password-policy.upper-case')}: ${response.data.upperCase}`)
                    }
                    if (response.data.special > 0) {
                        requirements.push(`${t('password-policy.special')}: ${response.data.special}`)
                    }
                    setPasswordPolicy({requirements: requirements, policy: response.data})
                })
                .catch(err => console.log(err))
        }
    })

    return (
        <Container text className="page withBottom">
            <Form onSubmit={handleSubmit}>
                <Form.Field className="special">
                    <label>Desired Username</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder="Desired username"
                        onChange={handleInputChange}
                        name="username" id="username" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.email-address')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.email-address-placeholder')}
                        onChange={handleInputChange}
                        name="emailaddress" id="emailaddress"
                        maxLength="256" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.first-name')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.first-name')}
                        onChange={handleInputChange}
                        name="firstname" id="firstname"
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.last-name')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.last-name')}
                        onChange={handleInputChange}
                        name="lastname" id="lastname"
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.company')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        placeholder={t('common-labels.company')}
                        onChange={handleInputChange}
                        name="company" id="company"
                        maxLength="100" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.function')}</label>
                    <Input fluid
                        placeholder={t('common-labels.function')}
                        onChange={handleInputChange}
                        name="function" id="function"
                        maxLength="50" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.telephone')}</label>
                    <Input fluid
                        placeholder={t('common-labels.telephone')}
                        onChange={handleInputChange}
                        name="telephone" id="telephone"
                        maxLength="50" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.address1')}</label>
                    <Input fluid
                        placeholder={t('common-labels.address1')}
                        onChange={handleInputChange}
                        name="address1" id="address1"
                        maxLength="100"/>
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.address2')}</label>
                    <Input fluid
                        placeholder={t('common-labels.address2')}
                        onChange={handleInputChange}
                        name="address2" id="address2"
                        maxLength="100" />
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Field label={t('common-labels.postcode')} control='input'
                        onChange={handleInputChange} name="postcode" id ="postcode"
                        maxLength="20" />
                    <Form.Field label={t('common-labels.city')} control='input'
                        onChange={handleInputChange} name="city" id ="city"
                        maxLength="100" />
                </Form.Group>
                <Form.Field>
                    <label>{t('common-labels.country')}</label>
                    <Dropdown
                        selection
                        options={supportedCountries}
                        value={country}
                        onChange={onCountryChange} />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.password')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password')}
                        onChange={handleInputChange}
                        name="password" id="password"
                        maxLength="256" />
                    <PasswordStrengthBar password={password}
                        shortScoreWord={t('password-strength-labels.short')}
                        scoreWords={[
                            t('password-strength-labels.weak'),
                            t('password-strength-labels.weak'),
                            t('password-strength-labels.okay'),
                            t('password-strength-labels.good'),
                            t('password-strength-labels.strong')
                        ]} />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.password-confirmation')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password-confirmation')}
                        onChange={handleInputChange}
                        name="passwordConfirmation" id="passwordConfirmation" />
                </Form.Field>
                <Button type="submit">{t('common-buttons.sign-up')}</Button>
            </Form>
            {errors.length === 0
                ? null
                : <Message
                    error
                    header={t('sign-up.errors.header')}
                    list={errors}
                /> }
            {passwordPolicy !== null && passwordPolicy.requirements.length > 0
                ? <Message positive size='mini'
                    header={t('password-policy.header')}
                    list={passwordPolicy.requirements}
                    />
                : null }
        </Container>
    )

}
