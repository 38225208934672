import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Container, Form, Input, Message } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import { useTranslation } from 'react-i18next';

import PasswordStrengthBar from 'react-password-strength-bar';

import { PageProps, PasswordPolicyApp, PasswordPolicyResponse } from '../types/types';
import { countDigits, countLowerCase, countSpecial, countUpperCase } from '../utils/passwords';

function ChangePassword(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [passwordCurrent, setPasswordCurrent] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [errors, setErrors] = useState<string[]>([])
    const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicyApp | null>(null)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const validationErrors = []

        // password validation, very simple, back end tests for more:
        if (passwordPolicy !== null) {
            if (passwordPolicy.policy.minimumLength > 0) {
                if (password.length < passwordPolicy.policy.minimumLength) {
                    validationErrors.push(t('sign-up.errors.password-too-short'))
                }
            }
            if (passwordPolicy.policy.digits > 0) {
                if (countDigits(password) < passwordPolicy.policy.digits) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-digits'))
                }
            }
            if (passwordPolicy.policy.lowerCase > 0) {
                if (countLowerCase(password) < passwordPolicy.policy.lowerCase) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-lowerCase'))
                }
            }
            if (passwordPolicy.policy.upperCase > 0) {
                if (countUpperCase(password) < passwordPolicy.policy.upperCase) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-upperCase'))
                }
            }
            if (passwordPolicy.policy.special > 0) {
                if (countSpecial(password) < passwordPolicy.policy.special) {
                    validationErrors.push(t('sign-up.errors.password-not-enough-special'))
                }
            }
        } else {
            if (password.length === 0) {
                validationErrors.push(t('sign-up.errors.missing-password'))
            }
        }

        if (password !== passwordConfirmation) {
            validationErrors.push(t('sign-up.errors.password-confirmation-invalid'))
        }

        setErrors(validationErrors)
        if (validationErrors.length > 0) {
            return
        }

        const form = new FormData()
        form.set('password-current', passwordCurrent)
        form.set('password-new', password)
        axios.post<void>('/api/profile/update-password', form, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(_ => props.history.push("/app/profile/changed/password"))
            .catch(reason => {
                if (reason.response.data.indexOf("401") > -1) {
                    setErrors([t('sign-in.errors.invalid-credentials')])
                    return
                }
                if (reason.response.data.indexOf("invalidPassword") > -1) {
                    setErrors([t('sign-up.errors.invalid-password')])
                    return
                }
                setErrors([t('sign-up.errors.request-failed', `HTTP status: ${reason.response.status}`)])
            })
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === "password-current") {
            setPasswordCurrent(event.target.value)
        } else if (event.target.name === "password-new") {
            setPassword(event.target.value)
        } else if (event.target.name === "passwordConfirmation") {
            setPasswordConfirmation(event.target.value)
        } else {
            console.error("sign-up: unsupported event target", event.target.value)
        }
    }

    const cancelPasswordChange = () => {
        props.history.push("/app/profile/view")
    }

    useEffect(() => {
        if (passwordPolicy === null) {
            axios.get<PasswordPolicyResponse>("/api/password-policy")
                .then(response => {
                    var requirements = []
                    if (response.data.minimumLength > 0) {
                        requirements.push(`${t('password-policy.minimum-length')}: ${response.data.minimumLength}`)
                    }
                    if (response.data.digits > 0) {
                        requirements.push(`${t('password-policy.digits')}: ${response.data.digits}`)
                    }
                    if (response.data.lowerCase > 0) {
                        requirements.push(`${t('password-policy.lower-case')}: ${response.data.lowerCase}`)
                    }
                    if (response.data.upperCase > 0) {
                        requirements.push(`${t('password-policy.upper-case')}: ${response.data.upperCase}`)
                    }
                    if (response.data.special > 0) {
                        requirements.push(`${t('password-policy.special')}: ${response.data.special}`)
                    }
                    setPasswordPolicy({requirements: requirements, policy: response.data})
                })
                .catch(err => console.log(err))
        }
    })

    return (
        <Container text className="page withBottom">
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>{t('common-labels.password-current')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password-current')}
                        onChange={handleInputChange}
                        name="password-current" id="password-current"
                        maxLength="256" />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.password-new')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password-new')}
                        onChange={handleInputChange}
                        name="password-new" id="password-new"
                        maxLength="256" />
                    <PasswordStrengthBar password={password}
                        shortScoreWord={t('password-strength-labels.short')}
                        scoreWords={[
                            t('password-strength-labels.weak'),
                            t('password-strength-labels.weak'),
                            t('password-strength-labels.okay'),
                            t('password-strength-labels.good'),
                            t('password-strength-labels.strong')
                        ]} />
                </Form.Field>
                <Form.Field>
                    <label>{t('common-labels.password-confirmation')}</label>
                    <Input fluid
                        label={{ icon: 'asterisk' }}
                        labelPosition='right corner'
                        type="password"
                        placeholder={t('common-labels.password-confirmation')}
                        onChange={handleInputChange}
                        name="passwordConfirmation" id="passwordConfirmation" />
                </Form.Field>
                <Button color='red' basic type="submit">{t('common-buttons.update')}</Button>
                <Button basic color='green' onClick={() => cancelPasswordChange()}>{t('common-buttons.cancel')}</Button>
            </Form>
            {errors.length === 0
                ? null
                : <Message
                    error
                    header={t('sign-up.errors.header')}
                    list={errors}
                /> }
            {passwordPolicy !== null && passwordPolicy.requirements.length > 0
                ? <Message positive size='mini'
                    header={t('password-policy.header')}
                    list={passwordPolicy.requirements}
                    />
                : null }
        </Container>
    )

}

export default ChangePassword;