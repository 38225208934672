import React, { useState } from 'react';
import { Button, Container, Header, Input, InputOnChangeData, Table } from 'semantic-ui-react'
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';

import { PageProps, UserSearchResult, UserProfile } from '../types/types'
import { handleNoAccess } from '../utils/utils'

export default function ManageUsersExisting(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [filteredUsers, setFilteredUsers] = useState<UserProfile[]>([])

    const [lastSearchSequence, setLastSearchSequence] = useState(0)
    const [lastSearchTerm, setLastSearchTerm] = useState("")
    
    const userSearchCriteriaChanged = (eventSource: any, eventData: InputOnChangeData) => {
        searchWithTerm(eventData.value)
    }

    const searchWithTerm = (term: string) => {
        if (term.length > 0) {
            setLastSearchSequence(lastSearchSequence+1)
            setLastSearchTerm(term)
            axios.post<UserSearchResult>("/api/manage/users/search", {query: term, searchID: "search-"+lastSearchSequence})
                .then(response => {
                    if (response.data.SearchID === "search-"+lastSearchSequence) {
                        setFilteredUsers(response.data.Accounts)
                    }
                })
                .catch(e => handleNoAccess(e, props.history))
        }
    }

    const modifyUserAccount = (userInfo: UserProfile) => {
        props.history.push(`/app/manage/users/edit/${userInfo.ID}`)
    }

    const deleteUserAccount = (userInfo: UserProfile) => {
        if (window.confirm(t('manage-users.delete-user-confirm'))) {
            axios.delete(`/api/manage/users/user/${userInfo.ID}`)
                .then(response => {
                    searchWithTerm(lastSearchTerm)
                })
                .catch(e => handleNoAccess(e, props.history))
        }
    }

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page">
            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.users-existing")}</Header>
            
            <Input icon='search' fluid placeholder={t('manage-users.placeholders.search')}
                onChange={userSearchCriteriaChanged}
                ref={input => input && input.focus()} />

            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('manage-users.column-headers.email')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-users.column-headers.first-name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-users.column-headers.last-name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.company')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('manage-users.column-headers.active')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {filteredUsers.length === 0
                    ? <Table.Row><Table.Cell colspan={6}>{t('manage-users.no-data')}</Table.Cell></Table.Row>
                    : filteredUsers.map((userInfo, i) => {
                            return <Table.Row>
                                <Table.Cell>{userInfo.EmailAddress}</Table.Cell>
                                <Table.Cell>{userInfo.FirstName}</Table.Cell>
                                <Table.Cell>{userInfo.LastName}</Table.Cell>
                                <Table.Cell>{userInfo.Company}</Table.Cell>
                                <Table.Cell>{ userInfo.EmailVerified
                                    ? <span>{t('manage-users.user-active.yes')}</span>
                                    : <span>{t('manage-users.user-active.no')}</span> }</Table.Cell>
                                <Table.Cell>{ userInfo.EmailVerified
                                    ? <>
                                            <Button color="green"
                                                onClick={() => modifyUserAccount(userInfo)}>{t('common-buttons.edit')}</Button>
                                            <Button color="red"
                                                onClick={() => deleteUserAccount(userInfo)}>{t('common-buttons.delete')}</Button>
                                        </>
                                    : <span>{t('manage-users.user-active.info')}</span>
                                }</Table.Cell>
                            </Table.Row>
                    })
                }
            </Table>

        </Container>
    )
}
