import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Container, Header } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import {format} from 'date-fns/esm'

import {InsecureHTML, NewsItem, PageProps} from '../types/types'

import { getLocale, handleNoAccess, idFromLocation } from '../utils/utils'

export default function ReadNews(props: PageProps) {

    const { t, i18n } = useTranslation();

    const [initialized, setInitialized] = useState(false)
    const [newsItem, setNewsItem] = useState<NewsItem | null>(null)

    const createMarkup = (data: string): InsecureHTML => {
        return {__html: data};
    }

    useEffect(() => {
        if (!initialized) {
            const newsID = idFromLocation()
            setInitialized(true)
            axios.get<NewsItem>(`/api/read-news/${newsID}`)
                .then(response => {
                    console.log(response.data)
                    setNewsItem(response.data)
                }).catch(reason => handleNoAccess(reason, props.history))
        }
    })

    const asDate = (input: string): Date => {
        return new Date(Date.parse(input))
    }

    const asLocalisedDateString = (input: Date): InsecureHTML => {
        const locale = getLocale(i18n.language)
        const day = format(input, "dd", {locale: locale})
        const monthWithYear = format(input, "LLLL yyyy", {locale: locale})
        return createMarkup(`<strong>${day}</strong> ${monthWithYear}`)
    }    

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page newsPage">
            {newsItem === null
                ? <></>
                : <Container>
                    <p><span className="date" dangerouslySetInnerHTML={asLocalisedDateString(asDate(newsItem.CreatedAt))} /></p>
                    <Header as='h2'>{newsItem.Subject}</Header>
                    <div dangerouslySetInnerHTML={createMarkup(newsItem.Content)} />
                  </Container>
                }            
        </Container>
    )
}