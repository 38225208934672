import { registerLocale } from  "react-datepicker"
import { History } from 'history'

import de from 'date-fns/locale/de';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi';
import nn from 'date-fns/locale/nn';
import sv from 'date-fns/locale/sv';

export function getLocale(input: string): any {
    switch (input) {
        case 'de':
            return de
        case 'dk':
            return da
        case 'en':
            return en
        case 'fi':
            return fi
        case 'no':
            return nn
        case 'se':
            return sv
        default:
            return de
    }
}

export function registerSupportedLocale() {
    registerLocale('de', de)
    registerLocale('dk', da)
    registerLocale('en', en)
    registerLocale('fi', fi)
    registerLocale('no', nn)
    registerLocale('se', sv)
}

export function getFormattedDate(date: Date): string {
    return `${date.getFullYear()}-${zeroPadded(date.getMonth()+1)}-${zeroPadded(date.getDate())}`
}

export function handleNoAccess(e: any, h: History): void {
    if (e.response.status === 401 && e.response.data.indexOf("access rejected") === 0) {
        h.push("/")
    } else {
        console.error(e)
    }
}

export function idFromLocation():string {
    return window.location.pathname.split("/").reverse()[0]
}

export function zeroPadded(n: number): string {
    return (n < 10) ? `0${n}` : ""+n
}