import React, { useEffect } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { PageSignOutProps } from './types/types';

export default function SignOut(props: PageSignOutProps) {

    useEffect(() => {
        if (props.sessionID === null) {
            props.callback()
            return
        }
        axios.post<void>("/api/signout", null, {
            headers: { 'Authorization': `Bearer ${props.sessionID}` }
        }).then(_ => props.callback())
    })

    return <Redirect to="/app/finished" />
}