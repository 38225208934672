import React, { useState, useEffect } from 'react';
import { Button, Container, Header, Table } from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';

import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';
import { PageProps, UserActivation } from '../types/types'
import { handleNoAccess } from '../utils/utils'

export default function ManageUsersWaiting(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [signedUpUsers, setSignedUpUsers] = useState<UserActivation[]>([])

    // -- User activation:

    const fetchNewSignUps = () => {
        axios.get<UserActivation[]>("/api/manage/users/waiting-room")
            .then(response => {
                setSignedUpUsers(response.data)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const activateAccount = (userInfo: UserActivation) => {
        props.history.push(`/app/manage/users/activate/${userInfo.UserID}`)
    }

    const deleteSignUp = (userInfo: UserActivation) => {
        if (window.confirm(t('manage-users.delete-signup-confirm'))) {
            axios.delete<void>(`/api/manage/users/waiting-room/activate/${userInfo.UserID}`)
                .then(_ => fetchNewSignUps())
                .catch(e => handleNoAccess(e, props.history))
        }
    }

    const [initialized, setInitialized] = useState(false)
   
    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchNewSignUps()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page">
            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.users-waiting")}</Header>
            
            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('manage-users.column-headers.created-at')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.user')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.company')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common-headers.actions')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {signedUpUsers.length === 0
                    ? <Table.Row><Table.Cell colspan={4}>{t('manage-users.no-data')}</Table.Cell></Table.Row>
                    : signedUpUsers.map((userInfo, i) => {
                        return <Table.Row>
                            <Table.Cell><Moment format="YYYY-MM-DD HH:mm:ss">{userInfo.CreatedAt}</Moment></Table.Cell>
                            <Table.Cell>
                                {userInfo.FirstName} {userInfo.LastName}
                                <br/>{userInfo.EmailAddress}
                            </Table.Cell>
                            <Table.Cell>
                                {userInfo.Company}
                            </Table.Cell>
                            <Table.Cell>
                                {userInfo.StatusVerified
                                    ? <>
                                        <Button color="green" size="mini"
                                            onClick={() => activateAccount(userInfo)}>{t('common-buttons.activate')}</Button>
                                        <Button color="red" size="mini"
                                            onClick={() => deleteSignUp(userInfo)}>{t('common-buttons.reject')}</Button>
                                    </>
                                    : <span>{t('common-labels.unverified')}</span>
                                }
                            </Table.Cell>
                        </Table.Row>
                    })
                }
            </Table>
        </Container>
    )
}