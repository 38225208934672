import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Divider, Header, Label } from 'semantic-ui-react'
import axios from 'axios';
import Moment from 'react-moment';
import 'react-data-grid/dist/react-data-grid.css';

import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';

import { NewsGetResponse, NewsListResponse, NewsItem, PageProps } from '../types/types';
import { handleNoAccess } from '../utils/utils';

export default function ManageNewsExisting(props: PageProps) {

    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();

    const [newsItems, setNewsItems] = useState<NewsGetResponse[]>([])

    const getTranslatedAudience = (aud: string): string | undefined => {
        if (aud === "is-employee") {
            return t('role-options.employee')
        }
        if (aud === "is-partner") {
            return t('role-options.partner')
        }
        if (aud === "is-wholesale") {
            return t('role-options.wholesale')
        }
    }

    const newsItemDelete = (newsItem: NewsItem) => {
        if (window.confirm(t('manage-news.delete-confirm').replace("{0}", newsItem.Subject))) {
            axios.delete<void>(`/api/manage/news/${newsItem.NewsID}`)
                .then(_ => fetch())
                .catch(e => { handleNoAccess(e, props.history) })
        }
    }

    const fetch = () => {
        axios.get<NewsListResponse>("/api/manage/news")
            .then(response => {
                setNewsItems(response.data.Items)
            })
            .catch(e => handleNoAccess(e, props.history))
    }

    const editItem = (itemID: string): void => {
        props.history.push(`/app/manage/news/edit/${itemID}`)
    }

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetch()
        }
    })

    if (props.sessionID === null) {
        return <Redirect to="/" />
    }
    
    return (
        <Container className="page">
            <Header as='h3'>{t("app-menu.administration")} / {t("app-menu.news-existing")}</Header>
            <Card.Group>
                {newsItems.map((newsItem, i) => {
                    return (
                        <Card>
                            <Card.Content>
                                <Card.Meta>{newsItem.News.Subject}</Card.Meta>
                                <Card.Description>{t('manage-news.overview.published-by')}:
                                    <br/><strong>{newsItem.News.CreatedBy}</strong>
                                    <br/>{t('manage-news.overview.published-at')}:
                                    <br/><strong><Moment format="YYYY-MM-DD HH:mm:ss">{newsItem.News.CreatedAt}</Moment></strong>
                                </Card.Description>
                                <Card.Content extra>
                                    <Divider />
                                    {newsItem.Audiences.map((audience, j) => {
                                        return <Label horizontal>{getTranslatedAudience(audience.Audience)}</Label>
                                    })}
                                </Card.Content>
                                <Card.Content extra>
                                    <Divider />
                                    <div className='ui two buttons'>
                                        <Button basic color='green' onClick={() => editItem(newsItem.News.NewsID)}>{t('common-buttons.edit')}</Button>
                                        <Button basic color='red' onClick={() => newsItemDelete(newsItem.News)}>{t('common-buttons.delete')}</Button>
                                    </div>
                                </Card.Content>
                            </Card.Content>
                        </Card>
                    )
                })}
            </Card.Group>
        </Container>
    )
}
